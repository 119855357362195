exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-page-slug-tsx": () => import("./../../../src/pages/{Page.slug}.tsx" /* webpackChunkName: "component---src-pages-page-slug-tsx" */),
  "component---src-templates-service-tsx": () => import("./../../../src/templates/service.tsx" /* webpackChunkName: "component---src-templates-service-tsx" */),
  "slice---src-components-footer-tsx": () => import("./../../../src/components/footer.tsx" /* webpackChunkName: "slice---src-components-footer-tsx" */),
  "slice---src-components-header-tsx": () => import("./../../../src/components/header.tsx" /* webpackChunkName: "slice---src-components-header-tsx" */)
}

